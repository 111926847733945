import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Configuracao from '../../../assets/config/appSettgins.json';
import { ControlStatesService } from 'src/app/controlStates.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public configuracao = Configuracao;
  public textospage: any = this.configuracao.portuguese.page;
  public url: string = "";
  constructor(private router: Router, private controlStatesService: ControlStatesService) { }


  ngOnInit(): void {
    this.idioma(this.controlStatesService.stateButtonIdioma)
  }
  atualizarIframe(rota: string) {
    switch (rota) {
      case 'cliente':
        this.url = this.configuracao.links.cliente
        break;
      case 'freedom':
        this.url = this.configuracao.links.freedom
        break;
      case 'profissional':
        this.url = this.configuracao.links.professional
        break;

      default:
        this.url = this.configuracao.links.cliente
    }
    if (this.url.trim() !== '') {
      const iframe = document.querySelector('iframe');
      if (iframe) {
        iframe.setAttribute('src', this.url);
      }
    }
  }

  public navegete() {
    this.router.navigate(['/']);
  }

  public idioma(tipo: string) {

    switch (tipo) {
      case 'portuguese':
        this.controlStatesService.stateButtonIdioma = tipo;
        this.textospage = this.configuracao.portuguese.page;
        break;
      case 'ingles':
        this.controlStatesService.stateButtonIdioma = tipo;
        this.textospage = this.configuracao.ingles.page;
        break;
      case 'espana':
        this.controlStatesService.stateButtonIdioma = tipo;
        this.textospage = this.configuracao.espana.page;
        break;
      case 'france':
        this.controlStatesService.stateButtonIdioma = tipo;
        this.textospage = this.configuracao.france.page;
        break;

      default:
        this.controlStatesService.stateButtonIdioma = tipo;
        this.textospage = this.configuracao.portuguese.page;
    }

  }
}
