import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControlStatesService {
  public stateButtonIdioma:any = "portuguese"
  private clickEventSubject = new Subject<void>();

  clickEvent$ = this.clickEventSubject.asObservable();

constructor() { }


emitClickEvent() {
  this.clickEventSubject.next();
}
}
