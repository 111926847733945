
<div class="view-frame">
  <iframe  is="x-frame-bypass" [src]="url"  title="Main content" name="contents"
  sandbox="allow-same-origin allow-scripts"
  frameborder="0" allowfullscreen> </iframe>
</div>


<div *ngIf="controlFooter" class="view-footer">
  <app-footer></app-footer>
</div>
