import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Configuracao from '../../../assets/config/appSettgins.json';
@Component({
  selector: 'app-page-frame',
  templateUrl: './page-frame.component.html',
  styleUrls: ['./page-frame.component.scss']
})
export class PageFrameComponent implements OnInit {
  public configuracao = Configuracao;
  constructor(private route: ActivatedRoute) { }
  public url: string = '';
  public controlFooter:boolean = false;

  ngOnInit() {
    this.route.params.subscribe(params => {
      const rota = params;
      switch (rota['rota']) {
        case 'cliente':
          this.url = this.configuracao.links.cliente
          break;
        case 'freedom':
          this.url = this.configuracao.links.freedom
          break;
        case 'profissional':
          this.url = this.configuracao.links.professional
          break;

        default:
          this.url = this.configuracao.links.cliente
      }
      if (this.url.trim() !== '') {
        const iframe = document.querySelector('iframe');
        if (iframe) {
          iframe.setAttribute('src', this.url );
        }
      }
    });

    setTimeout(()=> this.controlFooter = true, 300)
  }

  public atualizarIframe() {
    if (this.url.trim() !== '') {
      const iframe = document.querySelector('iframe');
      if (iframe) {
        iframe.setAttribute('src', this.url );
      }
    }
  }

}
