import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { PageFrameComponent } from './page-frame/page-frame.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import {MatIconModule} from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsModule,
    MatIconModule,
    BrowserModule,
     HttpClientModule
  ],
  declarations: [HomeComponent, PageFrameComponent],
  exports:[HomeComponent, PageFrameComponent],
})
export class ViewModule { }
