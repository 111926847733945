import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import Configuracao from '../../../assets/config/appSettgins.json';
import { ControlStatesService } from 'src/app/controlStates.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public configuracao = Configuracao;
  public textosTela: any = {};
  constructor(private router: Router, private controlStatesService: ControlStatesService, private renderer: Renderer2, private el: ElementRef) { }


  ngOnInit(): void {
   // this.controlStatesService.stateButtonIdioma == 'portuguese' ? this.textosTela = this.configuracao.portuguese.home : this.textosTela = this.configuracao.ingles.home;
    this.idioma(this.controlStatesService.stateButtonIdioma)

  }
  toggleFullScreen() {
    this.controlStatesService.emitClickEvent();
  }


  public navegete(rota: string) {
    this.router.navigate(['/frame', rota]);
  }

  public idioma(tipo: string) {

    switch (tipo) {
      case 'portuguese':
        this.controlStatesService.stateButtonIdioma = tipo;
        this.textosTela = this.configuracao.portuguese.home;
        break;
      case 'ingles':
        this.controlStatesService.stateButtonIdioma = tipo;
        this.textosTela = this.configuracao.ingles.home;
        break;
      case 'espana':
        this.controlStatesService.stateButtonIdioma = tipo;
        this.textosTela = this.configuracao.espana.home;
        break;
      case 'france':
        this.controlStatesService.stateButtonIdioma = tipo;
        this.textosTela = this.configuracao.france.home;
        break;

      default:
        this.controlStatesService.stateButtonIdioma = tipo;
        this.textosTela = this.configuracao.portuguese.home;
    }

  }

}
