import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './view/home/home.component';
import { PageFrameComponent } from './view/page-frame/page-frame.component';

const routes: Routes = [
  {path:'', component:HomeComponent},
  {path:'frame/:rota', component:PageFrameComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
