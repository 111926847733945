import { Component, Renderer2, ElementRef } from '@angular/core';
import { ControlStatesService } from './controlStates.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private renderer: Renderer2, private el: ElementRef,private controlStatesService: ControlStatesService) {
    this.controlStatesService.clickEvent$.subscribe(() => {
      this.toggleFullScreen()
    });
  }

  toggleFullScreen() {

    const element = this.el.nativeElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }

    if (document.exitFullscreen) {
      document.exitFullscreen();
    // @ts-ignore
    } else if (document.mozCancelFullScreen) {
      // @ts-ignore
      document.mozCancelFullScreen();
    // @ts-ignore
    } else if (document.webkitExitFullscreen) {
      // @ts-ignore
      document.webkitExitFullscreen();
    // @ts-ignore
    } else if (document.msExitFullscreen) {
      // @ts-ignore
      document.msExitFullscreen();
    }


  }

}
