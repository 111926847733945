<div  class="div-principal">
  <div class="conteudo">
    <div class="imagem-fundo">
      <img   src="/assets/image/home.svg" >
    </div>
    <div class="icones-idioma">
      <div></div>
      <img (click)="idioma('espana')"  src="/assets/image/icon-espana.svg" >
      <img (click)="idioma('france')"  src="/assets/image/icon-france.svg" >
      <div></div>
    </div>
    <div class="icones-idioma-colum">
      <img (click)="idioma('portuguese')"  src="/assets/image/icon-portugal.svg" >
      <img (click)="idioma('ingles')"  src="/assets/image/icon-ingles.svg" >
    </div>
    <div class="logo">
      <img (click)="toggleFullScreen()"  src="/assets/image/logo.svg" >
    </div>
    <div class="description">
      <span>{{textosTela.subDescription}}</span>
    </div>
    <div (click)="navegete('cliente')" class="button-client"><span>{{textosTela.client.textTop}}</span>
      <p>{{textosTela.client.textBoton}}</p>
    </div>
    <div (click)="navegete('freedom')" class="button-freedom"><span>{{textosTela.freedomBar.textTop}}</span>
      <p>{{textosTela.freedomBar.textBoton}}</p>
    <p></p>
    </div>
    <div (click)="navegete('profissional')" class="button-prof"><span >{{textosTela.professional.textTop}}</span>
      <p>{{textosTela.professional.textBoton}}</p>
    </div>
    <div class="description-detail"><span style="width: 200px;">
      {{textosTela.descriptionTop}}<br> <br><p style="width: 260px;">{{textosTela.descriptionBoton}}</p></span></div>
  </div>
</div>
