  <mat-button-toggle-group  name="favoriteColor" aria-label="Favorite Color">
    <div (click)="navegete()" class="icone-home">
      <img src="/assets/image/logo-home.svg" alt="">
      <span>{{textospage.homePage}}</span>
    </div>
    <span class="message-for-orient">{{textospage.descriptionPage}}</span>
    <mat-button-toggle (click)="atualizarIframe('cliente')">{{textospage.clientButton}}</mat-button-toggle>
    <mat-button-toggle (click)="atualizarIframe('freedom')">{{textospage.freedomBarButton}}</mat-button-toggle>
    <mat-button-toggle (click)="atualizarIframe('profissional')">{{textospage.professionalButton}}</mat-button-toggle>
  </mat-button-toggle-group>

