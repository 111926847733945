import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonToggleModule,
    BrowserModule,
    HttpClientModule
  ],
  declarations: [
    FooterComponent
  ],
  exports:[
    FooterComponent
  ]
})
export class ComponentsModule { }
